const select2 = {
    ajax: function (selector, endpoint, id_property, text_property,onProcessResults=function(){}) {
        $(selector).select2({
            placeholder: "Ketik yang anda cari...",
            minimumInputLength: 0,
            ajax: {
                url: endpoint,
                dataType: "json",
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term,
                        page: params.page || 1
                    }
                },
                cache: true,
                processResults: function (response, params) {
                    let results = response.data.results;
                    onProcessResults(results);
                    return {
                        results: $.map(results, function (item) {
                            return {
                                id: item[id_property],
                                text: item[text_property],
                            }
                        }),
                        pagination: response.data.pagination
                    };
                }
            }
        });
    }
}
export default select2;