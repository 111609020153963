<template>
    <div class="ol-wrapper ol-wrapper--large">
        <div class="ol-checkout-content">    
            <div class="ol-checkout-content__shipping">

				<div class="ol-checkout-content__shipping-list">
					<div v-if="loading.list" class="ol-checkout-content__shipping-list-body">
						<SkeletonLoader height="90px" width="100%" /><br>
						<SkeletonLoader height="90px" width="100%" /><br>
						<SkeletonLoader height="90px" width="100%" />
					</div>
				</div>

                <div v-if="!loading.list" class="ol-checkout-content__shipping-list">
					<Alert v-show="alertList.message !=null " :type="alertList.type" :message="alertList.message"/>
					<div class="ol-checkout-content__shipping-list-head">
						<div class="ol-checkout-content__shipping-list-head-item">
							Penerima
						</div>
						<div class="ol-checkout-content__shipping-list-head-item">
							Alamat 
						</div>
					</div>
					<div class="ol-checkout-content__shipping-list-body">
						<form id="pilih_alamat">
							<transition-group name="fade">
								<label v-for="item in addressActive" :key="item.id" class="ol-checkout-content__shipping-list-body-wrapper ">
									
									<div class="ol-checkout-content__shipping-list-body-item" style="display:block">
										<label   class="ol-checkout-content__shipping-list-body-item-label">
											<input class="ol-input--radio-check" name="default-address" type="radio" :value="item" v-model="picked">
											<i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
										</label>
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
										<p><b>{{item.name}}</b></p>
										<span>{{item.phone}}</span>
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
										{{item.address}}, {{item.postal_code}}, {{item.village_name}}, {{item.district_name}}, {{item.city_name}}, {{item.province_name}}
										
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
										<a title="Edit" class="ol-btn ol-btn--secondary" v-on:click="edit(item.id)" ><i class="far fa-edit"></i></a>
										<a class="ol-btn ol-del" style="margin-left:8px;" v-on:click="del(item.id)"><i class="fas fa-trash"></i></a>
									</div>
								</label>
								
							</transition-group>
							<input type="checkbox" id="slide-checkbox" class="slide-checkbox">
							<div class="slide-content">
								<label v-for="item in addressNonActive" :key="item.id" class="ol-checkout-content__shipping-list-body-wrapper ">
								
									<div class="ol-checkout-content__shipping-list-body-item" style="display:block">
										<label   class="ol-checkout-content__shipping-list-body-item-label">
											<input class="ol-input--radio-check" name="default-address" type="radio" :value="item" v-model="picked">
											<i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
										</label>
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
										<p><b>{{item.name}}</b></p>
										<span>{{item.phone}}</span>
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
										{{item.address}}, {{item.postal_code}}, {{item.village_name}}, {{item.district_name}}, {{item.city_name}}, {{item.province_name}}
									</div>
									<div class="ol-checkout-content__shipping-list-body-item">
										<a title="Edit" class="ol-btn ol-btn--secondary" v-on:click="edit(item.id)" ><i class="far fa-edit"></i></a>
										<a class="ol-btn ol-del" style="margin-left:8px;" v-on:click="del(item.id)"><i class="fas fa-trash"></i></a>
									</div>
								</label>
							</div>
							
							<label for="slide-checkbox" v-show="addresses.length > 1" class="slide-label ol-btn ol-btn-wishlist">Lihat Alamat Lainnya</label>
						</form>
						<label v-show="addresses.length < 1" class="ol-checkout-content__shipping-list-body-wrapper">
							Belum ada Alamat yang disimpan
						</label>
					</div>
				</div>
                <div class="ol-checkout-content__shipping-add"> 
					<a href="javascript:void(0)" @click="showPopup(true)" class="ol-btn">Tambah Alamat</a>
				</div>
				<div>
					<div class="dropship-container">
						<p>Kirim sebagai Dropshipper</p>
						<div style="height: 34px; width: 60px; position: relative;">
							<label class="switch">
								<input type="checkbox" id="flagDropship">
								<span class="slider round"></span>
							</label>
						</div>
					</div>
					<div id="form-dropship" style="display:none">
						<Alert v-if="alertDropship" type="danger" message="Kolom dibawah wajib dilengkapi."/>
						<!-- <div class="dropship-container">
							<p>Nama Pengirim</p>
							<input type="text" placeholder="Nama" v-model="dropship.name">
						</div>
						<div class="dropship-container">
							<p>No Telepon Pengirim</p>
							<input type="number" value="08" placeholder="081xxx..." v-model="dropship.phone">
						</div> -->

						<div class="row no-gutters">
							<div class="col-4"><b>Nama Alias</b></div>
							<div class="col-6">
								<select v-model="dropship.id" id="dropshipper"  @change="updateDropship()"  style="border: 1px solid #D2D2D2;padding: 4px;background: #f5f5f5;background-color: #f5f5f5;width: 100%;border-radius:8px;display:block;" >
									<option value="0">--Pilih Nama Alias--</option>
									<option v-for="dropshipper in dropshippers" :key="dropshipper.id" :value="dropshipper.id">{{dropshipper.name}} - {{dropshipper.phone}}</option>
								</select>
							</div>
							<div class="col-2" style="text-align:center;">
								<a href="/profile/dropshipper" class="ol-btn ol-btn--secondary" style="padding: 4px 8px;"><i class="ol-fa fa fa-plus"></i></a>
							</div>
						</div>
					</div>	
				</div>
            </div>
        </div>
        <div class="ol-checkout-nav">
            <router-link to="/cart" class="ol-btn ol-btn--secondary"><i class="ol-fa fas fa-arrow-left"></i>&emsp;Kembali</router-link>
            <button @click="toPaymentPage()" class="ol-btn">Selanjutnya&emsp;<i class="ol-fa fas fa-arrow-right"></i></button>
        </div>
        <Popup :title="title">
			<LoadingImg v-if="loading.save" />
			<div>
                <Alert v-show="validateBackend !=null " type="danger" :collection="validateBackend"/>

				<div class="ol-input-box ol-input-box--full">
					<label class="ol-input-label">Nama Lengkap</label>
					<input type="text" class="ol-input" v-model="input.name">
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Telepon</label>
					<input type="number" class="ol-input" value="08"  v-model="input.phone">
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Provinsi</label>
						<select id="provinces" class="ol-input ol-input-select select2" >
							<!-- <option value=""></option> -->
							<!-- <option v-for="province in provinces" :key="province.province_id" :value="province.province_id">{{province.province_name}}</option> -->
						</select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Kota/Kabupaten</label>
					<select id="cities" class="ol-input ol-input-select select2"> </select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Kecamatan</label>
					<select id="districts" class="ol-input ol-input-select select2"> </select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label ">Kelurahan</label>
					<select id="villages" class="ol-input ol-input-select select2"> </select>
				</div>
				<div class="ol-input-box ol-input-box--half">
					<label class="ol-input-label">Kode Pos</label>
					<input type="text" class="ol-input" v-model="input.postal_code">
				</div>
				<div class="ol-input-box ol-input-box--full">
					<label class="ol-input-label">
						Alamat
					</label>
					<textarea class="ol-input" v-model="input.address"></textarea>
					<label class="ol-product-content__sidebar-item-list-item-link">
						<input class="ol-input--radio-check" v-model="input.default" true-value=1 false-value=0 type="checkbox">
							Simpan sebagai alamat utama 
						<i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio"></i>
					</label>
				</div>

				<div class="ol-input-box ol-input-box--half">
					<button type="button" name="" v-on:click="savingAddress()" class="ol-btn">Simpan</button>
				</div>
			</div>
		</Popup>
    </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import LoadingImg from '@/components/loading'
import SkeletonLoader from '@/components/loading/skeletonLoader'
import Popup from '@/components/popup/Popup.vue'
import select2 from '@/lib/select2'
import globalUrl from '@/util/globallUrl'
import router from '../../../router'
const UNI = globalUrl.WS_UNI;
const URL = globalUrl.WS_PROFILE.DROPSHIPPER;
const BASE = process.env.VUE_APP_BASE_API_URL+"/";

export default {
    name:"CheckoutShipping",
    components:{
		LoadingImg,
		SkeletonLoader,
		Popup
    },
    data(){
        return{
            title: null,
            picked:null,
            input:{
				id:null,
                name: null,
				address: null,
				province: null,
				city: null,
				district: null,
				village: null,
				postal_code: null,
				phone: null,
				default: 0,
            },
			dropship:{
				name:"",
				phone:"",
				id:"",
			},
			loading:{
                list:true,
                save:false,
            },
			validateBackend:null,
			alertDropship:false,
            alertList:{
				message:null,
				type:"info",
			},
        }
    },
    computed:{
        ...mapGetters('profile/address', ['provinces','addresses']),
        ...mapGetters('transaction/checkout', ['shipping']),
		...mapGetters('profile/dropshipper', ['dropshippers']),
		...mapGetters("auth", ["user"]),
		addressActive() {
            return _.filter(this.addresses, {default: 1})
        },
		addressNonActive() {
            return _.filter(this.addresses, {default: 0})
        },
    },
    methods:{
        ...mapActions('profile/address', ['fetchProvinces','fetchAddresses','saveAddress','updateAddress','delAddress']),
		...mapActions("transaction/cart", ["save"]),
		...mapActions('profile/dropshipper', ['fetchDropshippers']),
        showPopup(show=true,isNew=true) {
            this.title="Edit Alamat";
            if(isNew){
                this.title="Tambah Alamat";
				this.input.id= null;
				this.input.name= null;
				this.input.address= null;
				this.input.province= null;
				this.input.city= null;
				this.input.district= null;
				this.input.village= null;
				this.input.postal_code= null;
				this.input.phone= null;
				this.input.default= 0;
				$("select#provinces,select#cities,select#districts,select#villages").val(null).trigger("change");
            }
            (show)? Popup.methods.show():Popup.methods.hide();
			this.validateBackend = null;
            this.loading.save = false;
        },
		updateDropship(){
			var data = $('#dropshipper option:selected').text();
			var arr = data.split(" - ");
			this.dropship.name = arr[0];
			this.dropship.phone = arr[1];
		},
		toPaymentPage(){
			if($("input#flagDropship").is(":checked")){
				if(!this.dropship.name || !this.dropship.phone || !this.dropship.id){
					this.alertDropship = true;
					return false;
				}
				console.log(this.dropship);
				this.$store.commit('transaction/checkout/setDropship', this.dropship);
			}else{
				this.$store.commit('transaction/checkout/setDropship', null);
			}
			
			this.$router.push('/checkout/payment');
		},

        async savingAddress(){
			this.alertList.message=null;
            this.validateBackend=null;
			this.loading.save = true;
			const result = (!this.input.id) ? await this.saveAddress(this.input) : await this.updateAddress(this.input);
			if(result.success) {
				if(this.addresses.length == 1) {
					this.picked = _.head(this.addresses);
				}
				this.showPopup(false);
				this.alertList.message = "Berhasil menyimpan alamat";
				this.alertList.type="success";
			}else{
				this.validateBackend=result.data.message ? {address:[" Gagal menyimpan alamat, coba beberapa saat lagi"]}:result.data;
				this.loading.save = false;
				// this.showPopup(false)  
			}
        },
		edit(id){
			this.title="Ubah Alamat";
            const address = _.find(this.addresses,{"id":id});
        	this.input.id = address.id;
        	this.input.name = address.name;
			this.input.address = address.address;
			this.input.province = address.province;
			this.input.city = address.city;
			this.input.district = address.district;
			this.input.village = address.village;
			this.input.postal_code = address.postal_code;
			this.input.phone = address.phone;
			this.input.default = Number(address.default);
            // $("select#provinces").val(this.input.province).trigger("change");
    		$("select#provinces").append(new Option(address.province_name, address.province, true, true)).trigger('change');
    		$("select#cities").append(new Option(address.city_name, address.city, true, true)).trigger('change');
    		$("select#districts").append(new Option(address.district_name, address.district, true, true)).trigger('change');
    		$("select#villages").append(new Option(address.village_name, address.village, true, true)).trigger('change');
            Popup.methods.show();
        },
        del(id){
			this.alertList.message=null;
            Swal.fire({
                text: "Yakin ingin dihapus?",
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#364b7b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loading.list = true;
                    const result = await this.delAddress(id);
                    if(result.success){
						this.message =  "Berhasil menghapus alamat";
						this.alertList.message = "Berhasil menghapus alamat";
						this.alertList.type="success";
                        this.loading.list = false;  
                    }else{
						this.alertList.message = result.data.message? result.data.message:"Gagal menghapus alamat, coba beberapa saat lagi";
						this.alertList.type="danger";
                        this.loading.list = false;  
                    }

                }
            })
        },
        jq(){
			let input = this.input;
			let villages = null;

			select2.ajax("select#provinces",BASE+UNI._PROVINCES,'province_id','province_name');

			$("select#provinces").change(function(){
				input.province = $(this).val();
				if(input.province){
					$("select#cities,select#districts,select#villages").val(null).trigger("change");				
					select2.ajax("select#cities",BASE+UNI._CITIES+"?province_id="+input.province,'city_id','city_name')
				}
			})

			$("select#cities").change(function(){
				input.city = $(this).val();
				if(input.city){
					$("select#districts,select#villages").val(null).trigger("change");		
					select2.ajax("select#districts",BASE+UNI._DISTRICTS+"?city_id="+input.city,'district_id','district_name')
				}
			})

			$("select#districts").change(function(){
				input.district =  $(this).val();
				if(input.district){
					$("select#villages").val(null).trigger("change");	
					select2.ajax("select#villages",BASE+UNI._VILLAGES+"?district_id="+input.district,'village_id','village_name',function(result){
						villages = result;
					})
				}
			});

			$("select#villages").change(function(){
				input.village = $(this).val();
				if(input.village) {
					let village = _.find(villages,{village_id:input.village});
					if(village){
						input.postal_code = village.village_postcode > 0 ? village.village_postcode:null;
					}
				}
			})

			$("input#flagDropship").change(function(){
				if($(this).is(":checked")){
					$("#form-dropship").slideDown("fast");
				}else{
					$("#form-dropship").slideUp("fast");
					this.dropship.name = "";
					this.dropship.phone = "";
					this.alertDropship = false;
				}
			})
		},
    },
    async mounted(){
        this.jq();
		//syncing cart
		this.save();

        await this.fetchAddresses() ? this.loading.list = false : this.$toast.open({ message: "Gagal mendapatkan data alamat", type: "error", duration: 3000, });
		await this.fetchDropshippers() ? this.loading.list=false : this.$toast.open({ message: "Gagal mendapatkan data alamat", type: "error", duration: 3000, });
		// let where = this.user.last_address_id ? { id: this.user.last_address_id } : {default: 1 }; //Kondisi jika mengambil defaul alamat dari yang sebelumnya
		// this.picked = this.shipping ? this.shipping : _.find(this.addresses,where);
		let where = {default: 1 };
        this.picked = _.find(this.addresses,where);

		$(document).on('select2:open', () => {
			document.querySelector('.select2-search__field').focus();
		});

		if(this.addresses.length == 0){
			this.showPopup(true);
		}
    },
	created(){
        if(this.order) this.$router.push("/profile/orders");
		
        this.$store.commit('transaction/checkout/setOrder', null);
    },
    watch:{
        picked:function(value){
            this.$store.commit('transaction/checkout/setShipping', value);
        },
    }
}
</script>

<style lang="css" scoped >
	@import '../../../assets/css/dropship.css';
	.slide-checkbox {
		display: none;
	}

	.slide-label {
		display: block;
		/* width: 100%;
		background-color: #ccc;
		color: #333;
		font-size: 16px;
		text-align: center;
		padding: 10px; */
		color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
		cursor: pointer;
		user-select: none;
	}

	.slide-content {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.5s ease-out;
	}


	.slide-checkbox:checked + .slide-content {
		max-height: 1000px;
		transition: max-height 0.5s ease-in;
	}

	.ol-product-content__sidebar-item-list-item-link {
		line-height: 1em;
		padding-top: 0;
  	}


</style>